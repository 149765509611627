<template>
  <div class="text-center" v-if="permission.read_perm === 1">
    <v-dialog
      v-model="dialog"
      :fullscreen="wWidth < 780"
      max-width="80%"
      @click:outside="clickOutside"
    >
      <template v-slot:activator="{ on, attrs }">
        <p id="archivedetail" v-bind="attrs" v-on="on" @click="detail"></p>
      </template>
      <v-container
        style="background: white; border-radius: 5px; position: relative;"
        v-if="result !== null"
      >
        <v-card
          flat
          style="
            z-index: 2;
            position: sticky;
            top: 0;
            background: white;
            border-bottom: 1px solid black;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div
            style="
              display: flex;
              jsutify-content: center;
              align-items: center;
              margin-left: 10px;
            "
          >
            <v-toolbar-title class="overline">
              <p
                v-if="wWidth > 780"
                style="font-size: 20px; margin: 0; font-weight: bold;max-width:700px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
              >
                Detail Dokumen | {{ result.archive_doc_no }} |
                {{ result.doc_no }}
              </p>
              <p
                v-else
                style="font-size: 14px; margin: 0; font-weight: bold; max-width:325px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
              >
                Detail Dokumen | {{ result.archive_doc_no }} |
                {{ result.doc_no }}
              </p>
            </v-toolbar-title>
          </div>

          <div class="doc-form-btn-1">
            <div
              v-if="
                result.document_type.id === 3 || result.document_type.id === 4
              "
              :style="wWidth < 780 ? `width:100%;` : ''"
            >
              <v-btn
                v-if="result.document_type.id === 4"
                rounded
                outlined
                small
                elevation="1"
                color="green"
                class="green--text"
                style="font-size:12px;"
                @click.stop="downloadPdf"
              >
                Download
              </v-btn>
            </div>
            <div v-else :style="wWidth < 780 ? `width:100%;` : ''">
              <div v-if="result.doc_status !== null">
                <div v-if="result.doc_status.id === 1">
                  <v-btn
                    :small="wWidth > 780"
                    tile
                    color="green"
                    style="color: white; width:100%;"
                    @click="borrow"
                  >
                    Pinjam Dokumen
                  </v-btn>
                </div>
              </div>
            </div>
            <v-btn
              v-if="wWidth > 780"
              rounded
              outlined
              small
              elevation="1"
              color="red"
              class="red--text"
              style="font-size:12px;margin-left:10px;"
              @click="close"
            >
              Tutup
            </v-btn>
          </div>
          <v-btn
            v-if="wWidth < 780"
            icon
            small
            color="red"
            class="red--text"
            style="font-size:12px;"
            @click="close"
          >
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-card>
        <v-row style="padding: 15px">
          <!-- <v-col md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Arsip
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="result.name"
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col> -->

          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Nama Dokumen
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="result.name"
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" md="2" style="padding: 0"></v-col>
          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No Dokumen
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="result.doc_no"
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Tipe
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="
                    result.document_type.id === 3
                      ? 'Dokumen Internal'
                      : result.document_type.id === 4
                      ? 'Dokumen Department'
                      : result.document_type.name
                  "
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" md="2" style="padding: 0"></v-col>
          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Kategori
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="result.category !== null ? result.category.name : ''"
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Kepemilikan
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="result.plant !== null ? result.plant.name : ''"
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" md="2" style="padding: 0"></v-col>
          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Sub Kategori
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="
                    result.sub_category === undefined ||
                    result.sub_category === null
                      ? ''
                      : result.sub_category.name
                  "
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  PIC
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="
                    result.pic_department_name === 'HRD'
                      ? 'HCGS'
                      : result.pic_department_name
                  "
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" md="2" style="padding: 0"></v-col>
          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Tgl Terbit
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="convertDate2(result.doc_date)"
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Penerbit
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  :value="result.published_by"
                  dense
                  readonly
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" md="2" style="padding: 0"></v-col>
          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Tgl Expired
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="convertDate2(result.doc_expiry_date)"
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Kota
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  :value="result.published_city"
                  readonly
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" md="2" style="padding: 0"></v-col>
          <v-col md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Jumlah
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="result.doc_qty"
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Nomor Rak
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="result.rack_index"
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" md="2" style="padding: 0"></v-col>
          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  uom
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="
                    result.doc_qty_unit !== null
                      ? result.doc_qty_unit.name
                      : '-'
                  "
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Status
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  :value="
                    result.doc_status !== null ? result.doc_status.name : '-'
                  "
                  readonly
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" md="2" style="padding: 0"></v-col>
          <v-col cols="12" md="5" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  File Name
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  readonly
                  :value="result.file"
                  dense
                  style="margin: 0"
                />
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" md="12" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Keterangan
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <div style="overflow:hidden;">
                  <vue-editor
                    class="textEditor"
                    style="height:400px;margin-bottom:80px;"
                    v-model="result.description"
                    :editorToolbar="toolbar"
                    disabled
                  ></vue-editor>
                </div>
                <!-- <v-textarea
                  readonly
                  :value="result.description"
                  dense
                  auto-grow
                  outlined
                  style="margin: 0"
                /> -->
              </v-col>
            </div>
          </v-col>
          <div
            v-if="result.doc_status !== null"
            style="padding: 0 10px; width: 100%"
          >
            <v-col md="12" style="padding: 0" v-if="result.doc_status.id === 2">
              <v-expansion-panels
                v-if="result.loan_detail !== null"
                class="mb-6"
                elevation="1"
              >
                <v-expansion-panel v-for="(item, i) in 1" :key="i">
                  <v-expansion-panel-header
                    expand-icon="mdi-menu-down"
                    style="font-weight: bold"
                  >
                    Detail Peminjaman
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container style="min-height: 50vh">
                      <v-row>
                        <v-col md="5" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="8" style="padding: 0">
                              <p
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Nomor Peminjaman
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                readonly
                                :value="result.loan_detail.loan_number"
                                dense
                                style="margin: 0"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col md="2" style="padding: 0"></v-col>
                        <v-col md="5" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="8" style="padding: 0">
                              <p
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Dipinjam Oleh
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                readonly
                                :value="result.loan_detail.pic_name"
                                dense
                                style="margin: 0"
                              />
                            </v-col>
                          </div>
                        </v-col>

                        <v-col md="5" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="8" style="padding: 0">
                              <p
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Department
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                :value="result.loan_detail.department.name"
                                readonly
                                dense
                                style="margin: 0"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col md="2" style="padding: 0"></v-col>
                        <v-col md="5" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="8" style="padding: 0">
                              <p
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Plant
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                readonly
                                :value="result.loan_detail.plant.name"
                                dense
                                style="margin: 0"
                              />
                            </v-col>
                          </div>
                        </v-col>

                        <v-col md="5" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="8" style="padding: 0">
                              <p
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Tgl Peminjaman
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                :value="
                                  convertDate(
                                    result.loan_detail.loan_start_date
                                  )
                                "
                                dense
                                readonly
                                style="margin: 0"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col md="2" style="padding: 0"></v-col>
                        <v-col md="5" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="8" style="padding: 0">
                              <p
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Tgl Pengembalian
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                :value="
                                  convertDate(result.loan_detail.loan_end_date)
                                "
                                dense
                                readonly
                                style="margin: 0"
                              />
                            </v-col>
                          </div>
                        </v-col>

                        <v-col md="5" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="8" style="padding: 0">
                              <p
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Jumlah
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                readonly
                                :value="result.loan_detail.qty"
                                dense
                                style="margin: 0"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col md="2" style="padding: 0"></v-col>
                        <v-col md="5" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="8" style="padding: 0">
                              <p
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Unit
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                :value="
                                  result.loan_detail.qty_uom !== null
                                    ? result.loan_detail.qty_uom.name
                                    : ''
                                "
                                readonly
                                dense
                                style="margin: 0"
                              />
                            </v-col>
                          </div>
                        </v-col>

                        <v-col md="5" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="8" style="padding: 0">
                              <p
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Dibuat oleh
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                readonly
                                :value="result.loan_detail.created_by_name"
                                dense
                                style="margin: 0"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col md="7" style="padding: 0"></v-col>

                        <v-col md="12" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="8" style="padding: 0">
                              <p style="margin: 0 0 0 5px">Keterangan</p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-textarea
                                readonly
                                :value="result.loan_detail.loan_purpose"
                                dense
                                auto-grow
                                outlined
                                style="margin: 0"
                              />
                            </v-col>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </div>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Archive-Detail',
  props: ['result', 'permission'],
  data() {
    return {
      toolbar: [[]],
      download: buildType.apiURL('download'),
      build: process.env.VUE_APP_BUILD_TYPE,
      wWidth: window.innerWidth,
      dialog: false,
      form: {},
      loading: false,
      select: null,
      search: null,
      itemss: [],
      new: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    ...mapGetters(['getDocId', 'getUserProfile'])
  },
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    ...mapMutations(['setLoaningDocumentName']),
    detail() {
      setTimeout(() => {
        console.log(this.result)
      }, 500)
    },
    clickOutside() {
      this.$emit('close')
    },
    close() {
      this.$emit('close')
      this.dialog = false
    },
    borrow() {
      this.setLoaningDocumentName([
        this.result.name,
        this.result.id,
        this.result.document_type.id,
        this.result.doc_qty,
        this.result.doc_qty_unit
      ])
      this.$router.push('/archive-management/loaning-list/form-loan')
    },
    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        let month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)

        switch (month) {
          case '01':
            month = 'JAN'
            break
          case '02':
            month = 'FEB'
            break
          case '03':
            month = 'MAR'
            break
          case '04':
            month = 'APR'
            break
          case '05':
            month = 'MAY'
            break
          case '06':
            month = 'JUN'
            break
          case '07':
            month = 'JUL'
            break
          case '08':
            month = 'AGS'
            break
          case '09':
            month = 'SEP'
            break
          case '10':
            month = 'OKT'
            break
          case '11':
            month = 'NOV'
            break
          case '12':
            month = 'DES'
            break
        }

        return day + ' ' + month + ' ' + year
      } else {
        return raw
      }
    },
    convertDate2(raw) {
      if (raw !== null) {
        if (raw === 'Invalid date') {
          return raw
        }
        const day = raw.slice(0, raw.indexOf('-'))
        let month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const year = raw.slice(raw.lastIndexOf('-') + 1)

        switch (month) {
          case '01':
            month = 'JAN'
            break
          case '02':
            month = 'FEB'
            break
          case '03':
            month = 'MAR'
            break
          case '04':
            month = 'APR'
            break
          case '05':
            month = 'MAY'
            break
          case '06':
            month = 'JUN'
            break
          case '07':
            month = 'JUL'
            break
          case '08':
            month = 'AGS'
            break
          case '09':
            month = 'SEP'
            break
          case '10':
            month = 'OKT'
            break
          case '11':
            month = 'NOV'
            break
          case '12':
            month = 'DES'
            break
        }

        return day + ' ' + month + ' ' + year
      } else {
        return raw
      }
    },
    downloadPdf() {
      const pValue = this.result
      const extension = pValue.file.slice(pValue.file.lastIndexOf('.'))
      axios({
        url: `${this.download}esanqua/archive_document/sop/${pValue.file}?access_type=view`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${pValue.doc_no + '-' + pValue.name + extension}`
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.doc-form-btn-1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 780px) {
  .doc-form-btn-1 {
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    width: 100%;
  }
}
</style>
